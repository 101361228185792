<template>
  <div :class="color">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 458.18 77.2">
      <title>wave-separator</title>
      <path class="fill"  d="M19.55,38.6c13.57,0,19.38,5.28,29.05,14.28C59.74,63,75,76.7,103.27,76.7S146.84,62.84,158,52.88c9.52-8.81,15.47-14.28,29-14.28s19.43,5.28,29.09,14.28C227.28,63,242.52,76.7,270.85,76.7s43.62-13.86,54.77-23.82c9.52-8.76,15.52-14.28,29.14-14.28s19.43,5.33,29.1,14.28C395.05,63,410.29,76.7,438.63,76.7a19,19,0,1,0,0-38.1c-13.62,0-19.43-5.34-29.15-14.29C398.34,14.17,383.1.5,354.76.5S311.14,14.36,300,24.31c-9.53,8.76-15.53,14.29-29.15,14.29s-19.43-5.29-29.09-14.29C230.61,14.17,215.37.5,187,.5s-43.67,13.86-54.67,23.81c-9.53,8.81-15.53,14.29-29.1,14.29s-19.38-5.29-29-14.29C63.12,14.36,47.84.5,19.55.5a19.05,19.05,0,0,0,0,38.1Z"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Separator',
  props: {
    color: {
      type: String,
      default: 'text'
    }
  }
}
</script>

<style scoped lang="css">
div {
  position: relative;
}
.jsmb-svg svg {
  position: absolute;
  top:50%;
  transform: translateY(-50%)
}
.white {
  color: #fff;
}
.primary {
  color: #27c6bd;
}
.link {
  color: #14acc2;
}
.text {
  color: #001c20;
}
.light {
  color: #f2f5fb;
}
.grey {
  color: #f2f5fb;
}
.dark {
  color: #828282;
}
.fill{
  fill: currentColor;
}
</style>
