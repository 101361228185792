<template>
  <div class="jsmb-mon-compte">

    <div class="container is-fullhd">

      <div class="jsmb-container-title">
        <h1 class="jsmb-title-account is-size-3">Mon compte</h1>
        <Separateur class="jsmb-account-separateur" color="primary"/>
      </div>

      <div class="columns is-desktop">
        <div class="column has-text-centered-tablet has-text-centered-mobile jsmb-switch-rwd">
          <div :class="[displayOnTouch ? 'dropdown' : 'panel', dropDownOpen ? 'is-active' : '']" @click="clickDrop"> 

            <div class="dropdown-trigger" v-if="displayOnTouch">
              <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                <span>Choisir un élément</span>
                <span class="icon is-small">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>

            <div :class="{ 'dropdown-menu ' : displayOnTouch }" role="menu">
              <div :class="{ 'dropdown-content control is-expanded' : displayOnTouch }">
                <router-link :to="{name: 'Profil'}" tag="a" :class="[$route.name == 'Profil' ? 'is-active' : '', displayOnTouch ? 'dropdown-item': 'panel-block']">
                  Mon profil
                  <span class="icon has-text-danger is-jsmb-warning" v-if="notification.profil"><i class="fas fa-exclamation-triangle"></i></span>
                </router-link>
                <router-link :to="{name: 'Annonces'}" tag="a" :class="[$route.name == 'Annonces' ? 'is-active' : '', displayOnTouch ? 'dropdown-item': 'panel-block']" v-if="userType === 'stockeur' || fullFeature">Mes Annonces</router-link>
                <router-link :to="{name: 'Reservations'}" tag="a" :class="[$route.name == 'Reservations' ? 'is-active' : '', displayOnTouch ? 'dropdown-item': 'panel-block']">
                  Mes Réservations
                  <span class="tag is-danger is-jsmb-notif" v-if="notification.booking">{{notification.booking}}</span>
                </router-link>
                <router-link :to="{name: 'Message'}" tag="a" :class="[$route.name == 'Message' ? 'is-active' : '', displayOnTouch ? 'dropdown-item': 'panel-block']">
                  Mes messages
                  <span class="tag is-danger is-jsmb-notif" v-if="notification.message">{{notification.message}}</span>
                </router-link>
                <router-link :to="{name: 'Comments'}" tag="a" :class="[$route.name == 'Comments' ? 'is-active' : '', displayOnTouch ? 'dropdown-item': 'panel-block']">
                  Mes commentaires
                  <span class="tag is-danger is-jsmb-notif" v-if="notification.comment">{{notification.comment}}</span>
                </router-link>
                <router-link :to="{name: 'Favorite'}" tag="a" :class="[$route.name == 'Favorite' ? 'is-active' : '', displayOnTouch ? 'dropdown-item': 'panel-block']">Mes favoris</router-link>
                <!-- <router-link to="/mon-compte/mes-paiements" tag="a" :class="[$route.name == 'Payments' ? 'is-active' : '', displayOnTouch ? 'dropdown-item': 'panel-block']">Mes paiements / mes factures</router-link> -->
              </div>
            </div>
          </div>
        </div>

        <div class="column is-9-desktop is-12-mobile">
          <router-view :class="this.$router.name=='Message' ? 'jsmb-account-comments' : 'jsmb-account-information'"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { GET_NOTIFICATIONS } from '@/store/modules/database/actions/database.actions'
import Separateur from '@/components/template/ui/Separator.vue'

export default {
  name: 'Account',
  components: {
    Separateur
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    titleTemplate: '%s - Mon Compte - Hivernage Bateau - Stockage bateau hors d\'eau',
    link: [
      { rel: 'canonical', href: 'https://www.jestockemonbateau.fr/mon-compte/' },
    ],
  },
  data () {
    return {
      currentTab: 0,
      dropDownOpen:false,
      userType: localStorage.getItem('user-type'),
      fullFeature: JSON.parse(localStorage.getItem('full-feature')),

      notification: {
        profil: 0,
        booking: 0,
        message: 0,
        comment: 0,
      }
    }
  },
  created () {
    let updateComment = false
    if (this.$router.currentRoute.name === "Comments") {
      updateComment = true
    }
    // Get Notifications
    this.$store.dispatch(GET_NOTIFICATIONS, {updateComment: updateComment}).then(
      resp => {
        this.notification = resp
      }
    )
  },
  methods: {
    clickDrop(){
      this.dropDownOpen = this.dropDownOpen ? false : true;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">
  .jsmb-mon-compte {
    overflow: hidden;
  }
  .panel-block:first-child {
    border-top:none;
  }
  @media screen and (max-width:1049px) {
    .jsmb-switch-rwd {
      background: #f2f5fb;
      padding:2rem 5rem 1rem;
    }
  }
  @media screen and (max-width:578px) {
    .jsmb-switch-rwd {
      padding:2rem;
    }
  }
  .jsmb-container-title {
    padding:2rem 2rem 2rem 0.5rem;
  }
  .jsmb-title-account {
    font-weight: 100;
    text-transform: uppercase;
  }
  .jsmb-account-separateur {
    width:35px;
  }
  .panel-block {
    background:#fff;
    border:none;
    padding:2rem 0.5rem 2rem 0.5rem;
    text-transform: uppercase;
    color:#818080;
    font-size:0.9rem;
    font-weight: bold;
    position: relative;
  }
  .panel-block.is-active,
  .panel-block:hover {
    background: #f2f5fb;
  }
  .panel-block:before {
    content:"";
    background: #fff;
    padding:2rem 0.5rem 2rem 0.5rem;
    position:absolute;
    left:-100%;
    display: block;
    width: 100%;
    height: inherit;
    top:0 !important;
  }
  .panel-block.is-active:before,
  .panel-block:hover:before {
    content:"";
    background: #f2f5fb;
    padding:2rem 0.5rem 2rem 0.5rem;
    position:absolute;
    left:-200%;
    display: block;
    width: 200%;
    height: 100%;
  }
  .panel-block:after {
    content: "";
    border-bottom: 1px solid #eeeeee;
    display: block;
    position: absolute;
    bottom: 0;
    z-index: 40;
    width: 90%;
  }
  .jsmb-account-information {
    padding:5rem;
    background: #f2f5fb;
    position:relative;
  }
  .jsmb-account-comments {
    padding: 0 5rem 5rem 5rem;
    background: #f2f5fb;
    position:relative;
  }
  .jsmb-account-comments:after,
  .jsmb-account-information:after {
    content: "";
    position: absolute;
    right: -100%;
    background: #f2f5fb;
    width: 100%;
    height: 100%;
    top: 0;
  }
  @media screen and (max-width:577px) {
    .jsmb-account-information {
      padding:2rem;
    }
  }

  /* fenetre détail */
  .jsmb-title-detail {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
  .field {
        margin-bottom: 0.5rem;
  }
  .label {
    font-weight: 100
  }
  .is-jsmb-notif {
    display: inline-flex;
    margin-left: auto;
    font-size: 0.6rem !important;
  }
  .is-jsmb-warning {
    display: inline-flex;
    margin-left: auto;
  }
</style>
